import React, { FC } from 'react';
import { ConfirmationModalButton } from './ConfirmationModalButton';

type TConfirmationModalActionsProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  isLoading?: boolean;
  hideCancelButton?: boolean;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  confirmationButtonClassName?: string;
};

const ConfirmationModalActions: FC<TConfirmationModalActionsProps> = ({
  handleCancel,
  handleConfirm,
  isLoading = false,
  confirmationButtonText = 'Confirm',
  confirmationButtonClassName,
  cancelButtonText = 'Cancel',
  hideCancelButton,
}) => (
  <div className="flex justify-center gap-x-2 md:gap-x-5">
    {!hideCancelButton && <ConfirmationModalButton onClick={handleCancel}>{cancelButtonText}</ConfirmationModalButton>}
    <ConfirmationModalButton
      isLoading={isLoading}
      onClick={handleConfirm}
      className={confirmationButtonClassName}
      isConfirm
    >
      {confirmationButtonText}
    </ConfirmationModalButton>
  </div>
);

export default ConfirmationModalActions;
