import { cloneElement, ReactElement, SyntheticEvent } from 'react';
import { useProtectWithLogin } from './hooks/useProtectWithLogin';

type TProtectWithLoginProps<T extends ReactElement<TClickableElementProps>> = {
  children: T;
  redirectUrl?: string;
};

type TClickableElementProps =
  | { onClick: () => void }
  | { onClickCapture: (e: SyntheticEvent) => void }
  | { href: string; target?: string };

const ProtectWithLogin = <T extends ReactElement<TClickableElementProps>>({
  children,
  redirectUrl,
  ...props
}: TProtectWithLoginProps<T>) => {
  const { shouldProtect, redirect } = useProtectWithLogin(redirectUrl);
  const childrenIsOfTypeLink = (children?.props as { href: string; target?: string }).target === '_blank';

  return cloneElement(children, {
    ...props,
    ...(shouldProtect
      ? {
          onClick: () => {
            redirect();
          },
          onClickCapture: (e: SyntheticEvent) => {
            e.stopPropagation();
            redirect();
          },
        }
      : {}),
    ...(!shouldProtect && childrenIsOfTypeLink ? { href: redirectUrl } : {}),
  });
};

export default ProtectWithLogin;
