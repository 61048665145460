'use client';

import React, { FC, useRef } from 'react';
import { useRouter as PagesUseRouter } from 'next/compat/router';
import { useRouter as AppUseRouter, usePathname } from 'next/navigation';
import { UseFormReturn } from 'react-hook-form';
import { TextInput } from 'flowbite-react';
import { Form } from '../Form/Form';
import { TSearchFormField } from './types';
import { SearchIcon } from '../Icons/SearchIcon';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { MAX_SEARCH_LENGTH } from './constants';
import { whitespacesValidator } from '../Form/validators/whitespacesValidator';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

type TSearchFormProps = {
  useFormReturn: UseFormReturn<TSearchFormField>;
  onFormSubmit?: () => void;
  onSearchInputClick?: () => void;
  searchInputPlaceholder?: string;
  searchResultPageGetter: (term: string) => string;
  restrictToAuthenticatedUsers?: boolean;
  allowEmptySearch?: boolean;
};

export const SearchForm: FC<TSearchFormProps> = ({
  useFormReturn,
  onFormSubmit,
  onSearchInputClick,
  searchInputPlaceholder = 'Search',
  searchResultPageGetter,
  restrictToAuthenticatedUsers = true,
  allowEmptySearch = false,
}) => {
  const { register, handleSubmit } = useFormReturn;
  const pathname = usePathname();
  const router = pathname !== null ? AppUseRouter() : PagesUseRouter();
  const { isAuthenticated } = useReactiveAuthenticatedUser();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { sendEvent } = useAnalytics();

  const { ref, ...rest } = register('search', {
    validate: {
      ...(allowEmptySearch ? {} : { whitespaces: whitespacesValidator }),
    },
  });

  const onSubmit = async ({ search }: { search: string }) => {
    const searchTerm = (search || '').trim();
    sendEvent('search_form_submit', {
      search_term: searchTerm,
    });
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    try {
      if (restrictToAuthenticatedUsers && !isAuthenticated) {
        return router?.push(searchResultPageGetter?.(`/search?term=${encodedSearchTerm}`));
      }

      router?.push(searchResultPageGetter?.(encodedSearchTerm));
      onFormSubmit?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      onFormSubmit?.();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        ref={(node) => {
          ref(node);
          inputRef.current = node;
        }}
        {...rest}
        id="search"
        color="t2-search"
        type="text"
        inputMode="search"
        icon={SearchIcon}
        placeholder={searchInputPlaceholder}
        maxLength={MAX_SEARCH_LENGTH}
        autoFocus
        onClick={() => onSearchInputClick?.()}
      />
    </Form>
  );
};
