import React from 'react';
import { CreateTerritoryForm } from '../CreateTerritory/CreateTerritoryForm';
import { CloseConfirmationModal } from '../ConfirmationModal/CloseConfirmationModal';
import { TUseCreateTerritoryReturn } from './types';

type TCreateTerritoryProps = Omit<
  TUseCreateTerritoryReturn,
  'isUserAuthenticated' | 'openCreateTerritoryModalWithAnalytics'
>;

export const CreateTerritory = ({
  showCreateTerritorySection,
  closeCreateTerritoryModalWithAnalytics,
  closeTerritoryConfirmationModalWithAnalytics,
  CreateTerritoryModal,
  TerritoryConfirmationModalComponent,
  closeCreateTerritoryModal,
  openCreateTerritoryModal,
  useFormReturn,
}: TCreateTerritoryProps) => {
  if (!showCreateTerritorySection) {
    return null;
  }

  return (
    <>
      <CreateTerritoryModal title="Launch a new Territory" containerClassName="sm:max-h-[650px]">
        <CreateTerritoryForm useFormReturn={useFormReturn} closeModal={closeCreateTerritoryModalWithAnalytics} />
      </CreateTerritoryModal>
      <CloseConfirmationModal
        ModalComponent={TerritoryConfirmationModalComponent}
        closeConfirmationModal={closeTerritoryConfirmationModalWithAnalytics}
        closeModal={closeCreateTerritoryModal}
        openModal={openCreateTerritoryModal}
      />
    </>
  );
};
