import React, { FC } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Territory } from 't2-graphql-types';
import { createPortal } from 'react-dom';
import DropdownLabel from '../Dropdown/DropdownLabel';
import Dropdown from '../Dropdown/Dropdown';
import NavbarTerritoriesDropdownItems from './NavbarTerritoriesDropdownItems';
import ChevronIcon from '../Navbar/ChevronIcon';
import IconButton from '../IconButton/IconButton';
import HamburgerMenuDark from '../../../public/images/hamburger-menu-dark.svg';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { TDropdownState } from '../Dropdown/types';
import { useT2Query } from '../../hooks/useT2Query';
import { useCreateTerritory } from '../Territory/hooks/useCreateTerritory';
import { CreateTerritory } from '../Territory/CreateTerritory';
import { PORTAL_ROOT } from '../../../utils/constants';
import { getTerritories } from '../Territory/queries/getTerritories';

type TNavbarTerritoriesDropdownProps = {
  userId?: string;
};

type TTerritories = Pick<Territory, 'id' | 'name' | 'slug'>[];

const NavbarTerritoriesDropdown: FC<TNavbarTerritoriesDropdownProps> = ({ userId }) => {
  const { sendEvent } = useAnalytics();
  const { data, loading, called } = useT2Query<{ territories: TTerritories }>(getTerritories, {
    variables: {
      where: {
        roles: {
          some: {
            memberships: {
              some: { user: { id: { equals: userId } }, status: { equals: 'ACTIVE' } },
            },
          },
        },
      },
      orderBy: { createdAt: 'asc' },
    },
    skip: !userId,
  });
  const createTerritory = useCreateTerritory({ isUserAuthenticated: !!userId });
  const territories = data?.territories || [];

  const territoryIds = territories ? territories?.map(({ id }) => id) : [];

  const handleStateChange = ({ isOpen }: TDropdownState) => {
    const customParams = { territory_ids: territoryIds };
    if (isOpen) {
      sendEvent('navbar_territory_dropdown_open', customParams);
    } else {
      sendEvent('navbar_territory_dropdown_close', customParams);
    }
  };

  const portalRootEl = document.getElementById(PORTAL_ROOT);

  return (
    <>
      <Dropdown
        menuContainerClassName="xs:min-w-[335px]"
        onStateChange={handleStateChange}
        renderLabel={({ isOpen }) => (
          <>
            <DropdownLabel
              className="hidden md:flex"
              label="Territories"
              rightAdornment={
                <ChevronIcon
                  className={cx('mr-1 ml-2 stroke-black-700', {
                    'scale-y-1 transition-all duration-200': !isOpen,
                    'scale-y-[-1] transition-all duration-200': isOpen,
                  })}
                  height={6}
                  width={12}
                />
              }
              isActive={isOpen}
            />
            <IconButton tag="div" color="white" className="md:hidden">
              <Image tabIndex={-1} src={HamburgerMenuDark} alt="Territories menu" width={16} height={16} />
            </IconButton>
          </>
        )}
        menu={
          <NavbarTerritoriesDropdownItems
            territories={territories}
            territoriesLoading={loading}
            territoriesCalled={called}
            openCreateTerritoryModalWithAnalytics={createTerritory.openCreateTerritoryModalWithAnalytics}
            isAuthenticated={!!userId}
          />
        }
      />
      {portalRootEl &&
        createPortal(<CreateTerritory {...createTerritory} />, document.getElementById(PORTAL_ROOT) as Element)}
    </>
  );
};

export default NavbarTerritoriesDropdown;
