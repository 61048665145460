import { TMembershipRolesRequirementsDurationOption } from 't2-app/src/components/TerritorySettings/TerritorySettingsMembership/TerritorySettingsMembershipRoles/MembershipRolesListItem/tabs/constants';
import { isProductionEnv, UNLOCK_WALLET_PUBLIC_ADDRESS } from '../../../config';

export const ROLE_TERRITORY_MEMBERSHIP_CONST_MIN = 0;
export const ROLE_TERRITORY_MEMBERSHIP_CONST_MAX = 1000;

export const LOCK_SYMBOL = 't2';

export const VALID_DURATION_FOR_CRYPTO = [-1, 30, 365, ...(isProductionEnv ? [] : [1])];
export const VALID_CURRENCY_LABEL_FOR_CRYPTO = ['USDC'];

export enum MembershipRolesRequirementsClaimDuration {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  FOREVER = 'Forever',
}

export const MEMBERSHIP_ROLES_REQUIREMENTS_DURATION_OPTIONS: TMembershipRolesRequirementsDurationOption[] = [
  {
    label: MembershipRolesRequirementsClaimDuration.MONTHLY,
    value: 30,
  },
  {
    label: MembershipRolesRequirementsClaimDuration.YEARLY,
    value: 365,
  },
  {
    label: MembershipRolesRequirementsClaimDuration.FOREVER,
    value: -1,
  },
];

export const UNLOCK_T2_REFERRER_PUBLIC_ADDRESS = isProductionEnv
  ? '0x8587366696c9C949663375FabbD6D2D587b2acE1'
  : UNLOCK_WALLET_PUBLIC_ADDRESS;
export const UNLOCK_T2_REFERRER_FEE_BASIS_POINT = '1000'; // 10%;
