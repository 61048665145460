import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import getTerritoryPageUrl from 'src/services/pagesUrl/getTerritoryPageUrl';
import { useRouter } from 'next/compat/router';
import { TERRITORY_NAME_USED_ERROR_MESSAGE } from 't2-keystone/src/schema/Territory/validations/constants';
import { TerritoryForm } from '../TerritoryForm/TerritoryForm';
import { TTerritoryFields } from '../TerritoryForm/types';
import { createCustomTerritory } from './mutations/createCustomTerritory';
import { showTerritoryErrorNotification } from '../TerritoryForm/helpers/showTerritoryErrorNotification';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { getTerritories } from '../Territory/queries/getTerritories';
import Button from '../Button';
import { FormType } from '../TerritoryForm/constants';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { getAllTerritoriesQuery } from '../TerritoryList/queries/getAllTerritoriesQuery';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';

type TCreateTerritoryFormProps = {
  useFormReturn: UseFormReturn<TTerritoryFields>;
  closeModal: () => void;
};

export const isTerritoryNameTakenError = (error) =>
  error.graphQLErrors.some(({ message }) => message === TERRITORY_NAME_USED_ERROR_MESSAGE);

export const CreateTerritoryForm: FC<TCreateTerritoryFormProps> = ({ useFormReturn, closeModal }) => {
  const router = useRouter();
  const [createTerritoryRequest] = useMutation(createCustomTerritory, {
    refetchQueries: [getTerritories, getAllTerritoriesQuery],
  });
  const { sendEvent } = useAnalytics();
  const { refetch } = useAuthenticatedUser();

  const SubmitButton = (
    <Button
      variant="secondary"
      rounded
      type="submit"
      isLoading={useFormReturn.formState.isSubmitting}
      className="min-w-[160px] font-secondary !font-normal py-2 !px-6 text-white-900 !rounded-[33px] w-full flex items-center self-center justify-center"
      spinnerProps={{
        color: 'white',
      }}
    >
      Launch Territory
    </Button>
  );

  const onSubmit = async ({ name, description }: TTerritoryFields) => {
    try {
      const createTerritoryResponse = await createTerritoryRequest({
        variables: {
          name,
          description,
        },
      });
      const territoryId = createTerritoryResponse.data.createCustomTerritory.id;

      sendEvent('create_territory_success', { territory_id: territoryId });

      router?.push(getTerritoryPageUrl(createTerritoryResponse.data.createCustomTerritory.slug as string));
      refetch?.();
      closeModal();
    } catch (error) {
      sendEvent('create_territory_error');
      if (isTerritoryNameTakenError(error)) {
        useFormReturn.setError('name', { type: 'nameTaken', message: TERRITORY_NAME_USED_ERROR_MESSAGE });
        return;
      }

      showTerritoryErrorNotification();
      closeModal();
    }
  };

  return (
    <TerritoryForm
      useFormReturn={useFormReturn}
      onSubmit={onSubmit}
      submitButton={SubmitButton}
      formType={FormType.CREATE}
      hideMembershipCost={getFeatureFlag('createNewTerritoryAsV2')}
    />
  );
};
