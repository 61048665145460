'use client';

import React, { FC } from 'react';
import { useRouter } from 'next/navigation';
import { Territory } from 't2-graphql-types';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import DropdownItem from '../Dropdown/DropdownItem';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import getTerritoryPageUrl from '../../services/pagesUrl/getTerritoryPageUrl';
import Label from '../Label/Label';
import Typo from '../Typography/Typo';
import { isWithinDays } from './helpers/isWithinDays';
import { Plus2Icon } from '../Icons/Plus2Icon';
import { GlobeIcon } from '../Icons/GlobeIcon';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage/CloudinaryResponsiveImage';
import { NavbarTerritoriesNoMemberships } from './NavbarTerritoriesNoMemberships';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';
import { getTerritoryDiscoveryPageUrl } from '../../services/pagesUrl/getTerritoryDiscoveryPageUrl';
import DropdownSeparator from '../Dropdown/DropdownSeparator';

type TNavbarTerritoriesDropdownItemsProps = {
  territories: Array<Pick<Territory, 'id' | 'slug' | 'name' | 'createdAt' | 'logo'>>;
  territoriesLoading: boolean;
  territoriesCalled: boolean;
  openCreateTerritoryModalWithAnalytics: () => void;
  isAuthenticated: boolean;
};

const NavbarTerritoriesDropdownItems: FC<TNavbarTerritoriesDropdownItemsProps> = ({
  territories,
  territoriesCalled,
  territoriesLoading,
  openCreateTerritoryModalWithAnalytics,
  isAuthenticated,
}) => {
  const router = useRouter();
  const { sendEvent } = useAnalytics();

  const handleClickTerritory = (territory: Pick<Territory, 'id' | 'slug'>) => {
    sendEvent('navbar_territory_dropdown_item_click', { territory_id: territory.id });
    router.push(getTerritoryPageUrl(territory.slug!));
  };

  const ctaButtons = [
    {
      name: 'explore',
      content: 'Discover Territories',
      icon: <GlobeIcon className="w-4 h-4 md:w-8 md:h-8 stroke-none" />,
      onClick: () => router.push(getTerritoryDiscoveryPageUrl()),
    },
  ];

  return (
    <>
      <DropdownItem className="items-center">
        <div className="w-full text-left text-t2-neutral-grey06 p-4">My Territories</div>
      </DropdownItem>
      <NavbarTerritoriesNoMemberships
        isAuthenticated={isAuthenticated}
        territories={territories}
        territoriesCalled={territoriesCalled}
        territoriesLoading={territoriesLoading}
      />
      <div className="max-h-[210px] overflow-y-scroll">
        {territories.map((territory) => {
          const dateToCheck: string = territory?.createdAt;
          const numDays: number = 30;
          const isWithinThirtyDays: boolean = isWithinDays(dateToCheck, numDays);

          return (
            <DropdownItem key={territory.id} className="max-h-[52px]">
              <DropdownClickableItem
                className="items-center py-5"
                onClick={() => handleClickTerritory(territory)}
                leftAdornment={
                  <CloudinaryResponsiveImage
                    containerClassName="h-[100%] w-[100%]"
                    className="rounded-full object-cover w-[100%] h-[100%]"
                    alt={`territory ${territory.name} logo`}
                    image={territory.logo}
                  />
                }
                leftAdornmentContainerClassName="md:w-8 md:h-8 "
              >
                <div className="w-full text-left">t/{territory.name}</div>
                <div className="ml-3 w-[60px]">
                  {isWithinThirtyDays && (
                    <Label backgroundColor="lightGreen" size="small" textColor="primary">
                      <Typo variant="small-p" className="!text-xs">
                        New!
                      </Typo>
                    </Label>
                  )}
                </div>
              </DropdownClickableItem>
            </DropdownItem>
          );
        })}
      </div>
      <DropdownItem className="items-center pt-2 pb-3">
        <DropdownSeparator />
      </DropdownItem>
      {ctaButtons.map(({ name, content, icon, onClick }) => (
        <DropdownItem key={name}>
          <DropdownClickableItem
            className="items-start pl-0"
            leftAdornment={icon}
            leftAdornmentContainerClassName="md:w-8 md:h-8 ml-4 mr-2"
            onClick={onClick}
          >
            {content}
          </DropdownClickableItem>
        </DropdownItem>
      ))}
      <ProtectWithLogin>
        <DropdownClickableItem
          className="items-start pl-0"
          leftAdornment={<Plus2Icon className="w-4 h-4 md:w-8 md:h-8 stroke-none" />}
          leftAdornmentContainerClassName="md:w-8 md:h-8 ml-4 mr-2"
          onClick={openCreateTerritoryModalWithAnalytics}
        >
          Launch a New Territory
        </DropdownClickableItem>
      </ProtectWithLogin>
    </>
  );
};
export default NavbarTerritoriesDropdownItems;
