import { gql } from '@apollo/client';

export const getTerritories = gql`
  query getTerritories($where: TerritoryWhereInput = {}, $orderBy: [TerritoryOrderByInput!] = []) {
    territories(where: $where, orderBy: $orderBy) {
      id
      name
      slug
      createdAt
      logo {
        id
        publicUrl
        publicUrlTransformed
        _meta {
          public_id
          height
          width
        }
      }
    }
  }
`;
