import { ReactNode, createContext, useContext } from 'react';
import { useAsidePanel } from '../hooks/useAsidePanel';

type TAsidePanelContextValue = {
  isOpen: boolean;
  closeAsidePanel: VoidFunction;
  openAsidePanel: VoidFunction;
  setOnOpen?: (onOpen: VoidFunction) => void;
  setOnClose?: (onClose: VoidFunction) => void;
};

type TAsidePanelContextProviderProps = {
  children: ReactNode;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
};

const initialState: TAsidePanelContextValue = {
  isOpen: false,
  closeAsidePanel: () => undefined,
  openAsidePanel: () => undefined,
};

const AsidePanelContext = createContext(initialState);

export const AsidePanelContextProvider = ({ children, onClose, onOpen }: TAsidePanelContextProviderProps) => {
  const value = useAsidePanel({ onClose, onOpen });

  return <AsidePanelContext.Provider value={value}>{children}</AsidePanelContext.Provider>;
};

export const useAsidePanelContext = () => useContext(AsidePanelContext);
