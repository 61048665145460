import { useCallback, useState } from 'react';

type TUseAsidePanelProps = {
  initialState?: boolean;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  setOnOpen?: (onOpen: VoidFunction) => void;
  setOnClose?: (onClose: VoidFunction) => void;
};

export const useAsidePanel = ({ initialState = false, onClose, onOpen }: TUseAsidePanelProps = {}) => {
  const [handlers, setHandlers] = useState({ onClose, onOpen });
  const [isOpen, setIsOpen] = useState(initialState);

  const closeAsidePanel = useCallback(() => {
    handlers.onClose?.();
    setIsOpen(false);
  }, [handlers.onClose]);

  const openAsidePanel = useCallback(() => {
    handlers.onOpen?.();
    setIsOpen(true);
  }, [handlers.onOpen]);

  const setOnClose = useCallback(
    (onClose: VoidFunction) => setHandlers((prev) => ({ ...prev, onClose })),
    [setHandlers],
  );

  const setOnOpen = useCallback((onOpen: VoidFunction) => setHandlers((prev) => ({ ...prev, onOpen })), [setHandlers]);

  return { isOpen, closeAsidePanel, openAsidePanel, setOnClose, setOnOpen };
};
