'use client';

import React from 'react';
import { useHasPermission } from '../Permissions/hooks/useHasPermission';
import LinkButton from '../LinkButton/LinkButton';
import { DISCORD_URL } from '../../../utils/constants';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { PUBLIC_URL } from '../../../config';

export const NotFoundLinkButtons = () => {
  const { isAuthenticated } = useReactiveAuthenticatedUser() || {};
  const hasPermission = useHasPermission();
  const marketingSiteUrl = PUBLIC_URL;

  return (
    <>
      <LinkButton
        variant="primary"
        href={isAuthenticated && hasPermission('GLOBAL_CAN_ACCESS_APP') ? '/' : marketingSiteUrl}
        className="flex items-center"
      >
        <div className="relative mr-7 before:absolute before:bg-home-icon before:w-5 before:h-5 before:top-1/2 before:-translate-y-1/2" />
        Go to Home
      </LinkButton>
      <LinkButton variant="ghost" href={DISCORD_URL} className="flex items-center">
        <div className="relative mr-7 before:absolute before:bg-discord-outline before:w-5 before:h-5 before:top-1/2 before:-translate-y-1/2" />
        Contact Support
      </LinkButton>
    </>
  );
};
