type TPlus2IconProps = {
  className?: string;
};

export const Plus2Icon = ({ className }: TPlus2IconProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.625" y="0.625" width="30.75" height="30.75" rx="15.375" fill="#F9F9F9" />
    <rect
      x="0.625"
      y="0.625"
      width="30.75"
      height="30.75"
      rx="15.375"
      stroke="#242424"
      strokeWidth="1.25"
      strokeDasharray="2.5 2.5"
    />
    <path d="M16 12.6667V19.3334" stroke="#242424" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.667 16H19.3337" stroke="#242424" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
