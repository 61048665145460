type TGlobeIconProps = {
  className?: string;
};

export const GlobeIcon = ({ className }: TGlobeIconProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0003 2.66675C20.0003 5.33341 21.2307 11.0561 21.3337 16.0001C21.2307 20.9441 20.0003 26.6667 16.0003 29.3334M16.0003 2.66675C12.0003 5.33341 10.77 11.0561 10.667 16.0001C10.77 20.9441 12.0003 26.6667 16.0003 29.3334M16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001M16.0003 2.66675C23.3641 2.66675 29.3337 8.63628 29.3337 16.0001M16.0003 29.3334C23.3642 29.3334 29.3337 23.3638 29.3337 16.0001M16.0003 29.3334C8.63654 29.3334 2.66699 23.3638 2.66699 16.0001M2.66699 16.0001C5.33366 20.0001 11.0564 21.2305 16.0003 21.3334C20.9443 21.2305 26.667 20.0001 29.3337 16.0001M2.66699 16.0001C5.33366 12.0001 11.0564 10.7697 16.0003 10.6667C20.9443 10.7697 26.667 12.0001 29.3337 16.0001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
