import React, { FC } from 'react';
import { Territory } from 't2-graphql-types';
import isEmpty from 'lodash/isEmpty';

type TNavbarTerritoriesNoMemberships = {
  isAuthenticated: boolean;
  territories: Pick<Territory, 'id' | 'slug' | 'name' | 'createdAt' | 'logo'>[];
  territoriesCalled: boolean;
  territoriesLoading: boolean;
};
export const NavbarTerritoriesNoMemberships: FC<TNavbarTerritoriesNoMemberships> = ({
  isAuthenticated,
  territories,
  territoriesCalled,
  territoriesLoading,
}) => {
  if (isAuthenticated && territoriesCalled && !territoriesLoading && !isEmpty(territories)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 text-[14px] mx-5 my-4">
      <div className="text-t2-neutral-grey07 font-semibold">You’re not a member of any Territories yet!</div>
      <div className="text-t2-neutral-grey05">Explore Territories to find your niche.</div>
    </div>
  );
};
