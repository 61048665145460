import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { useReactiveHasPermission } from '../../Permissions/hooks/useHasPermission';
import { useAnalytics } from '../../AnalyticsProvider/AnalyticsProvider';
import { getFeatureFlag } from '../../FeatureFlags/getFeatureFlag';
import { DEFAULT_TERRITORY_MEMBERSHIP_COST } from '../../TerritoryForm/constants';
import { useModal } from '../../Modal/useModal';
import { AnalyticsModalType } from '../../Modal/constants';
import { TUseCreateTerritoryReturn } from '../types';

type TUseCreateTerritoryProps = {
  isUserAuthenticated: boolean;
};

export const useCreateTerritory = ({ isUserAuthenticated }: TUseCreateTerritoryProps): TUseCreateTerritoryReturn => {
  const hasPermission = useReactiveHasPermission();
  const hasPermissionToCreateTerritory =
    hasPermission('GLOBAL_CAN_CREATE_TERRITORY') || hasPermission('GLOBAL_CAN_CREATE_CUSTOM_TERRITORY');
  const { sendEvent } = useAnalytics();
  const showCreateTerritorySection =
    getFeatureFlag('userGeneratedTerritories') && (!isUserAuthenticated || hasPermissionToCreateTerritory);

  const defaultValues = {
    name: '',
    slug: '',
    description: '',
    logo: '',
    territoryMembershipCost: getFeatureFlag('createNewTerritoryAsV2') ? 0 : DEFAULT_TERRITORY_MEMBERSHIP_COST,
  };

  const useFormReturn = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onCloseTerritoryModal = () => {
    const {
      getValues,
      formState: { defaultValues, isSubmitting },
    } = useFormReturn;

    if (!isSubmitting && !isEqual(getValues(), defaultValues)) {
      openTerritoryConfirmationModal();
    }
  };

  const closeCreateTerritoryModalWithAnalytics = () => {
    sendEvent('new_territory_abandon');
    closeCreateTerritoryModal();
  };

  const closeTerritoryConfirmationModalWithAnalytics = () => {
    sendEvent('new_territory_abandon');
    closeTerritoryConfirmationModal();
  };

  const {
    openModal: openCreateTerritoryModal,
    closeModal: closeCreateTerritoryModal,
    ModalComponent: CreateTerritoryModal,
  } = useModal({
    closeModalCallback: onCloseTerritoryModal,
    analytics: { modalName: AnalyticsModalType.newTerritory },
  });

  const openCreateTerritoryModalWithAnalytics = () => {
    sendEvent('new_territory_button_click');
    openCreateTerritoryModal();
  };

  const {
    openModal: openTerritoryConfirmationModal,
    closeModal: closeTerritoryConfirmationModal,
    ModalComponent: TerritoryConfirmationModalComponent,
  } = useModal({ analytics: { modalName: AnalyticsModalType.abandonTerritory } });

  return {
    showCreateTerritorySection,
    closeCreateTerritoryModalWithAnalytics,
    closeTerritoryConfirmationModalWithAnalytics,
    CreateTerritoryModal,
    openCreateTerritoryModalWithAnalytics,
    TerritoryConfirmationModalComponent,
    closeCreateTerritoryModal,
    openCreateTerritoryModal,
    useFormReturn,
    isUserAuthenticated,
  };
};
