import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { SearchForm } from '../SearchForm/SearchForm';
import { SearchHistory } from '../SearchHistory/SearchHistory';
import { getSearchPageUrl } from '../../services/pagesUrl/getSearchPageUrl';

type TSearchFormContainerProps = {
  onFormSubmit: () => void;
};

export const SearchFormContainer: FC<TSearchFormContainerProps> = ({ onFormSubmit }) => {
  const defaultValues = {
    search: '',
  };

  const useFormReturn = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  return (
    <div className="mx-1 mb-1 mt-10 sm:mb-0 sm:mt-1">
      <SearchForm
        useFormReturn={useFormReturn}
        onFormSubmit={onFormSubmit}
        searchInputPlaceholder="What would you like to search?"
        searchResultPageGetter={getSearchPageUrl}
      />
      <SearchHistory onItemClick={onFormSubmit} searchResultPageGetter={getSearchPageUrl} />
    </div>
  );
};
